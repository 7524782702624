@use "sass:map";
@use "sass:selector";
@use "sass:color";
@use "sass:math";

/** SCSS DOC: _colors.scss **/

$acc-blue: #004176 !default;
$teal: #198dae !default;
$green-blue: #24ccae !default;
$dark-grey: #676a72 !default;
$alt-grey: #a3988c !default;
$green: #06a022 !default;
$orange: #da8210 !default;
$red: #dd1a1a !default;
$blue: #2b58c0 !default;
$purple: #976ad2 !default;
$magenta: #c34198 !default;
$white: rgb(251, 247, 247) !default;
$black: rgb(19, 18, 18) !default;
// Currently Un used in the design but part of bootstrap.
$indigo: #6610f2 !default;
$pink: #e83e8c !default;
$yellow: #ffc107 !default;
$cyan: #17a2b8 !default;
$gray-100: mix($white, $black, 10%) !default;
$gray-200: mix($white, $black, 20%) !default;
$gray-300: mix($white, $black, 30%) !default;
$gray-400: mix($white, $black, 40%) !default;
$gray-500: mix($white, $black, 50%) !default;
$gray-600: mix($white, $black, 60%) !default;
$gray-700: mix($white, $black, 70%) !default;
$gray-800: mix($white, $black, 80%) !default;
$gray-900: mix($white, $black, 90%) !default;
$grays: () !default;
$grays: map-merge(
	(
		"100": $gray-100,
		"200": $gray-200,
		"300": $gray-300,
		"400": $gray-400,
		"500": $gray-500,
		"600": $gray-600,
		"700": $gray-700,
		"800": $gray-800,
		"900": $gray-900,
	),
	$grays
);
$colors: () !default;
$colors: map-merge(
	(
		"blue": $blue,
		"indigo": $indigo,
		"purple": $purple,
		"pink": $pink,
		"red": $red,
		"orange": $orange,
		"yellow": $yellow,
		"green": $green,
		"teal": $teal,
		"cyan": $cyan,
		"white": $white,
		"gray": $gray-600,
		"gray-dark": $gray-800,
	),
	$colors
);
$theme-palette: () !default;

$theme-palette: map-merge(
	(
		primary: $teal,
		secondary: $dark-grey,
		success: $green,
		shade: $gray-500,
		warning: $orange,
		alert: $red,
		navigation: $blue,
		accent: $green-blue,
		info: $alt-grey,
		highlight: $purple,
		acc: $acc-blue,
	),
	$theme-palette
);
/** Setting link color variables in scss **/
$link-color-light:					get-theme-color(accent, 3) !default;
$link-color-light--hover:			get-theme-color(accent, 5) !default;
$link-color-light--visited:			color.scale( get-theme-color(accent, 2), $saturation: -70%) !default;
$link-color-dark:					get-theme-color(accent, -3) !default;
$link-color-dark--hover:			get-theme-color(accent, -5) !default;
$link-color-dark--visited:			color.scale( get-theme-color(accent, -2), $saturation: -70%) !default;





$cor-green: #6fc284;
$cor-yellow: #ffd44f;
$cor-yellow-orange: #faa74b;
$cor-orange: #f15d4c;
$cor-red: #ed1c00;
$COR: () !default;
$COR: map-merge(
	(
		I: $cor-green,
		IIA: $cor-yellow,
		IIB: $cor-yellow-orange,
		III: $cor-orange,
		III-NOBENIFIT: $cor-orange,
		III-HARM: $cor-red,
	),
	$COR
);
$loe-dark-blue: rgb(62, 111, 183);
$loe-mid-blue: rgb(101, 156, 211);
$loe-light-blue: rgb(161, 193, 230);
$loe-pale-blue: rgb(181, 213, 229);
$LOE: () !default;
$LOE: map-merge(
	(
		A: $loe-dark-blue,
		B-NR: $loe-mid-blue,
		B-BR: $loe-light-blue,
		C-LD: $loe-pale-blue,
		C-EO: $loe-pale-blue,
	),
	$LOE
);

$credit-AAPA: #e8c536;
$credit-ABP: #007a5b;
$credit-ABPMOCII: #0b6d4c;
$credit-ABPMOCIV: #105a3b;
$credit-ATCEU: #775744;
$credit-CE: #a8a8a8;
$credit-CME: #00a15b;
$credit-CNE: #198dae;
$credit-CNErx: #52549e;
$credit-COP: #775891;
$credit-CPE: #992e2e;
$credit-ECME: #d16528;
$credit-IPCE:#6c36b3;
$credit-MOC: #9a1267;
$credit-MOCII: #9a1267;
$credit-MOCIV: #d3248d;
$credit-ACHE : #9733a5;

$CreditColors: () !default;
$CreditColors: map-merge(
	(
		AAPA: $credit-AAPA,
		ABP: $credit-ABP,
		ABPMOCII: $credit-ABPMOCII,
		ABPMOCIV: $credit-ABPMOCIV,
		ATCEU: $credit-ATCEU,
		CE: $credit-CE,
		CME: $credit-CME,
		CNE: $credit-CNE,
		CNErx: $credit-CNErx,
		COP: $credit-COP,
		CPE: $credit-CPE,
		ECME: $credit-ECME,
		IPCE:$credit-IPCE,
		MOC: $credit-MOCII,
		MOCII: $credit-MOCII,
		MOCIV: $credit-MOCIV,
		ACHE: $credit-ACHE,
	),
	$CreditColors
);
$pathway-invasive-cardiology: #c8b02f;
$pathway-congenital-heart-disease: #fdb918;
$pathway-noninvasive-cardiology: #947b33;
$pathway-ecg-stress-test: #d9691f;
$pathway-vascular-medicine: #ae171c;
$pathway-pulmonary-hypertension: #d60e7e;
$pathway-prevention: #a01d51;
$pathway-heart-failure: #410d49;
$pathway-valvular-heart-disease: #4f4190;
$pathway-arrhythmias-EP: #005b37;
$pathway-pericardial: #0079ad;
$pathway-acute-coronary-syndromes: #009974;
$pathway-stable-ischemic-heart-disease: #0f3e65;
$pathway-special: #00b6f1;
$pathway-pandemic: #8e2582;
$PathwayColors: () !default;
$PathwayColors: map-merge(
	(
		invasive: $pathway-invasive-cardiology,
		congenital: $pathway-congenital-heart-disease,
		noninvasive: $pathway-noninvasive-cardiology,
		ecg: $pathway-ecg-stress-test,
		vascular: $pathway-vascular-medicine,
		pulmonary: $pathway-pulmonary-hypertension,
		prevention: $pathway-prevention,
		hf: $pathway-heart-failure,
		valvular: $pathway-valvular-heart-disease,
		arrhythmias: $pathway-arrhythmias-EP,
		pericardial: $pathway-pericardial,
		acute: $pathway-acute-coronary-syndromes,
		ischemic: $pathway-stable-ischemic-heart-disease,
		special: $pathway-special,
		pandemic: $pathway-pandemic,
	),
	$PathwayColors
);

$pathwayV2-invt: #c8b02f;
$pathwayV2-pchd: #fdb918;
$pathwayV2-imag: #947b33;
$pathwayV2-vasc: #ae171c;
$pathwayV2-pulmvd: #d60e7e;
$pathwayV2-valv: #4f4190;
$pathwayV2-ep: #005b37;
$pathwayV2-ischem: #0f3e65;
$pathwayV2-special: #00b6f1;
$pathwayV2-prev: #a01d51;
$pathwayV2-hf: #410d49;

$PathwayColorsV2: () !default;
$PathwayColorsV2: map-merge(
	(
		invt: $pathwayV2-invt,
		pchd: $pathwayV2-pchd,
		imag: $pathwayV2-imag,
		vasc: $pathwayV2-vasc,
		pulmvd: $pathwayV2-pulmvd,
		prev: $pathwayV2-prev,
		hf: $pathwayV2-hf,
		valv: $pathwayV2-valv,
		ep: $pathwayV2-ep,
		ischem: $pathwayV2-ischem,
		special: $pathwayV2-special
	),
	$PathwayColorsV2
);


$twitter: #000;
$facebook: #3b5998;
$linkedin: #0077b5;
$apple-store: #ea4cc0;
$google-play: #ef6c00;
$youtube: #bb0000;
$instagram: #8134af;
$SocialColors: () !default;
$SocialColors: map-merge(
	(
		twitter: $twitter,
		facebook: $facebook,
		linkedin: $linkedin,
		apple-store: $apple-store,
		google-play: $google-play,
		youtube: $youtube,
		instagram: $instagram,
	),
	$SocialColors
);


$registry-cpmi: #f22418;
$registry-afib:#ffb300;
$registry-cathpci: #0086d5;
$registry-icd: #80b800;
$registry-impact:#4e1f73;
$registry-pinnacle-primary:#0e4f1b;
$registry-pinnacle-accent:#b3c4c4;
$registry-focus:#d6560d;
$registry-pcibleeding:#c0268e;
$registry-laao:#00667a;
$registry-pvi:#00947f;
$registry-auxdata:#04adad;
$registry-ststvt:#a20062;
$registry-diabetes:#7893b8;

$RegistryColors: () !default;
$RegistryColors: map-merge(
	(
		cpmi: $registry-cpmi,
		afib: $registry-afib,
		cathpci:$registry-cathpci,
		icd:$registry-icd,
		impact:$registry-impact,
		pinnacle:$registry-pinnacle-primary,
		pinnacle-accent:$registry-pinnacle-accent,
		focus:$registry-focus,
		pcibleeding:$registry-pcibleeding,
		laao:$registry-laao,
		pvi:$registry-pvi,
		auxdata:$registry-auxdata,
		ststvt:$registry-ststvt,
		diabetes:$registry-diabetes
	),
	$RegistryColors
);



$journal-jc: #002e5a; 
$journal-adv:#0000FF;	
$journal-asia:#ec2939;	
$journal-tran:#1788c2;	
$journal-cvonc:#00ae81;
$journal-case:#f37321;
$journal-ep:#8a5e96;	
$journal-hf:#991326;	
$journal-invt:#038e92;	
$journal-imag:#c18a0e;	

$journal-label-jc: "Journal of the American College of Cardiology"; 
$journal-label-adv:"JACC: Advances";	
$journal-label-asia:"JACC: Asia";	
$journal-label-tran:"JACC: Basic to Translational Science";	
$journal-label-cvonc:"JACC: CardioOncology";
$journal-label-case:"JACC: Case Reports";
$journal-label-ep:"JACC: Clinical Electrophysiology";	
$journal-label-hf:"JACC: Heart Failure";	
$journal-label-invt:"JACC: Cardiovascular Interventions";	
$journal-label-imag:"JACC: Cardiovascular Imaging";	

$JournalsJACC: () !default;
$JournalsJACC: map-merge(
	(
	jc:$journal-jc,
	jc-adv:$journal-adv,
	jc-asia:$journal-asia,
	jc-tran:$journal-tran,
	jc-cvonc:$journal-cvonc,
	jc-case:$journal-case,
	jc-ep:$journal-ep,
	jc-hf:$journal-hf,
	jc-invt:$journal-invt,
	jc-imag:$journal-imag,

	),
	$JournalsJACC
);


/*

Link Colors: Light

Link colors of a lighter shade in all of its states, default, hover, visited. Typically used to enhance visibility on dark backgrounds.

$link-color-dark			- #{$link-color-dark}; Link Color dark			
$link-color-dark--hover	- #{$link-color-dark--hover}; Link Color dark--hover	
$link-color-dark--visited	- #{$link-color-dark--visited}; Link Color dark--visited	

Weight:2

Styleguide DesignBase.Colors.LinksLight
 */
/*

Link Colors

Link colors  in all of its states, default, hover, visited.


$link-color-light			- #{$link-color-light}; Link Color light			
$link-color-light--hover	- #{$link-color-light--hover}; Link Color light--hover	
$link-color-light--visited	- #{$link-color-light--visited}; Link Color light--visited	
$link-color-dark			- #{$link-color-dark}; Link Color dark			
$link-color-dark--hover	- #{$link-color-dark--hover}; Link Color dark--hover	
$link-color-dark--visited	- #{$link-color-dark--visited}; Link Color dark--visited	

Weight:1

Styleguide DesignBase.Colors.Links
 */