/*
MicroSite

<p class="m-b_3">A MicroSite, existing within a larger website, offers a strategic approach for encompassing a semi-independent initiative of an organization, all while retaining a robust link to the main site. While it upholds most of the branding guidelines of the main site, a MicroSite has its unique design, acting as a concentrated unit.
</p><p class="m-b_3">The creation of a MicroSite enables businesses to effectively spotlight specific campaigns or products with a distinctive touch, cater to particular audience segments, and maintain a uniform user experience. Despite living relatively independently from the main site's structure and supplementary content, it still provides fluid navigation and integration with the primary website.
</p><p class="m-b_3">This arrangement presents a flexible and specialized platform for delivering customized messaging, engaging users in a personalized way, and accomplishing specific objectives that may necessitate a unique online presence.
</p>

Styleguide Recipes.MicroSite

*/

/*
Hero Image with CTA


The "Hero Image with CTA" component signifies a hero section that includes a superimposed call-to-action. This element typically combines a return feature to the main site and should be exclusively used on microsites.

Markup:
<div data-item="hero-image-cta" class="hero relative isolation_isolate grid rows_3 rows_2:md columns_4:md columns_5:lg columns_2 m-t_n4 m-x_n4  overflow_hidden " style="--hero-overlay-opacity:.9; --row-1:2.5rem; --row-2:min-content;">
    {{> "Recipes.MicroSite.Sub.HeroImage.BackgroundImage" modifier_class="" }}
    <div class="z_1 row-start_start row_en:mdd_1 col-start_start col-end_end flex flex_row justify_start">
        {{> "Recipes.MicroSite.Sub.HeroImage.BreadcrumbNav" modifier_class="" }}
    </div>
    <div class="relative row-start_2 row-end_3 col-start_start col-end_3:md col-end_end p-y_0 p-r_4 m-l_0:md">
        {{> "Recipes.MicroSite.Sub.PageTitleTreatment" modifier_class="m-t_5:lg m-t_4 m-b_0" }}
    </div>
    <div class="col-end_end col-start_n3:md col-start_n4:lg col-start_start font_0:lg font_n1 p-t_0:md p-t_5 relative row-end_end row-end_end:md row-start_2:md row-start_3">
        {{> "Recipes.MicroSite.Sub.HeroImage.CTAHighlight" modifier_class="z_1" }}
    </div>
</div>

MarkupWrapperClasses:  p-t_4

SubComponents:
Recipes.MicroSite.Sub.HeroImage.BackgroundImage - Hero Background Image
Recipes.MicroSite.Sub.HeroImage.BreadcrumbNav - Hero Breadcrumb Navigation
Recipes.MicroSite.Sub.PageTitleTreatment - Page Title Treatment
Recipes.MicroSite.Sub.HeroImage.CTAHighlight - CTA Highlight

Weight: 0

NOTE: The Hero Image with CTA component is used to create impactful hero sections with a compelling call-to-action overlay. It combines a visually appealing hero image with a title, description, and call-to-action button.

caution:  This item is not finalized and might be subject to change


Styleguide Recipes.MicroSite.HeroImageWithCTA

*/

/*
Micro Branding with CTA Button


The "Hero Image with CTA" component signifies a hero section that includes a superimposed call-to-action. This element typically combines a return feature to the main site and should be exclusively used on microsites.

Markup:
<div data-item="hero-image-cta" class="flex flex_column flex_row:md font_3 font_4:md font_5:lg hero isolation_isolate overflow_hidden relative" style="--hero-overlay-opacity:.9;">
 {{> "Recipes.MicroSite.Sub.HeroImage.BackgroundImageMicro" }}
  <div class="items_center p-l_4 p-y_4">
    <span data-item="hero-title" class="c_white isolation_isolate relative">
        <span class="display_none:md"><a href="#" class="h:c_white h:underline font_bold c_white-8 expanded-click-area h:c_white"><i class="fas fa-solid fa-arrow-left m-l_n3" aria-hidden="true"></i></a></span>
        <span>Join Us:</span>
        <span>Become</span>
        <span>an</span>
        <span>ACC</span>
        <span>Member</span>
    </span>
  </div>
  <div class="flex_none grid items_center justify_center m-l_auto:md p-x_5 p-y_4 relative">
    <div class="">
        <a href="#" class="br_1 br_solid br_white-3 btn btn-primary font_medium not-link shadow_bevel-bold">Join Now</a>
    </div>
  </div>
</div>

MarkupWrapperClasses:  p-t_4

SubComponents:
Recipes.MicroSite.Sub.HeroImage.BackgroundImageMicro - Background Image
Recipes.MicroSite.Sub.HeroImage.BreadcrumbNav - Hero Breadcrumb Navigation
Recipes.MicroSite.Sub.HeroImage.CTAButton - CTA Button

Weight: 0

NOTE: Micro Branding is used to create compelling call-to-action overlay. It combines a visually appealing hero image with a call-to-action button.

caution:  This item is not finalized and might be subject to change


Styleguide Recipes.MicroSite.MicroBrandingWithButtton

*/

/*
CTA Highlight

The CTA Highlight component is used to create a highlighted call-to-action section.

Modifier: inverted - This modifier class is used to create an inverted version of the CTA Highlight component, which can be applied when a dark background is present.

Markup:
<div data-item="cta-callout" class="{{modifier_class}} c_white color_inherit font_0 isolation_isolate m-b_4 m-t_n4 m-x_4 m-x_5:lg p_4 p_5:lg reading-typography relative z_1">
      <h2 data-item="cta-title" class="font_medium m-t_0">The Value of UX and Member Engagement</h2>
      <p data-item="cta-description" class="font-size_up-1 lh_2">Accusantium iure non nam quaerat nesciunt ad. Vero nobis ea quo laudantium eum esse. Officiis eum nemo id minus ut itaque et et beatae. Voluptas est incidunt.</p>
      <div class="grid justify_center">
        <button  class="btn btn-primary not-link">Get Started</button>
      </div>
</div>


MarkupWrapperClasses: 

Weight: 0

NOTE: The CTA Highlight component is effective for drawing attention to important calls-to-action on a website or application. When using the 'inverted' modifier class, it provides a visually striking effect when placed on a dark background.

caution:  This item is not finalized and might be subject to change

Styleguide  Recipes.MicroSite.Sub.HeroImage.CTAHighlight

*/

/*
CTA Button

The CTA Button is just a centered button for the highlighted call-to-action.


Markup:
<div class="grid justify_center items_center {{modifier_class}}">
  <button class="btn btn-primary shadow_1 expanded-click-area">Get Started</button>
</div>

MarkupWrapperClasses: 

Weight: 0

NOTE: The CTA Highlight component is effective for drawing attention to important calls-to-action on a website or application. When using the 'inverted' modifier class, it provides a visually striking effect when placed on a dark background.

caution:  This item is not finalized and might be subject to change

Styleguide  Recipes.MicroSite.Sub.HeroImage.CTAButton 

*/

/*
Hero Image Breadcrumb Nav

The Hero Image Breadcrumb Nav is a sub-component used within the Hero Image with CTA component to display a breadcrumb navigation on top of the hero image.

Markup:
<nav data-item="hero-image-breadcrumb-nav" class="{{modifier_class}}  relative isolation_isolate p-y_3 p-x_4 c_white-8 w_au:mdto font_n1 font_0:md">
    <!-- Breadcrumb navigation content goes here -->
	<a href="#" class="h:c_white h:underline font_bold c_white-8 expanded-click-area h:c_white"><i class="fas fa-arrow-left"></i> Back</a> |
	The page this was a child of</a>
</nav>

MarkupWrapperClasses: 

Weight: 0

NOTE: The Hero Image Breadcrumb Nav sub-component is used to provide breadcrumb navigation on top of the hero image within the Hero Image with CTA component. It focuses on displaying the immediate parent only in the breadcrumb trail.

caution:  This item is not finalized and might be subject to change

Styleguide  Recipes.MicroSite.Sub.HeroImage.BreadcrumbNav

*/


/*
Bespoke: Page Title Treatment

MicroSite Page Title Treatment component is used to style and emphasize page titles.

Modifier: font-color - This modifier class is used to customize the font color of the page title.

Markup:
<h1 class="c_white  {{ modifier_class }} font_5:lg font_4:md font_3 isolation_isolate relative" data-item="hero-title" >
  <span>Transformaing</span>
  <span>Cardiovascular</span>
  <span>Care</span>
  <span>For</span>
  <span>All</span>
  <span>Around</span>
  <span>The</span>
  <span>World</span>
</h1>


MarkupWrapperClasses: bg_hf-2 p_5 hero max-w_40

Weight: 0

NOTE: The Page Title Treatment component is ideal for styling and emphasizing page titles. The "font-color" modifier can be applied to customize the font color of the page title. This component is commonly used in MicroSites to provide a special impact and lightly brand them as a contained experience.

caution:  This item is not finalized and might be subject to change

Styleguide   Recipes.MicroSite.Sub.PageTitleTreatment

*/
.hero{
  --hero-overlay-color: var(--acc);
  --hero-overlay-opacity:.75
}
%after-background-transparency{
  position: relative;
  isolation: isolate;
  &::after{
    content:'';
    background-color: var(--hero-overlay-color);
    opacity: var(--hero-overlay-opacity);
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
    inset:0px;
    position: absolute;
    z-index: -1;
  }
}

[data-item="hero-image-breadcrumb-nav"],[data-item="cta-callout"],[data-item="hero-title"] span{
  @extend %after-background-transparency;
}

[data-item="hero-title"]{
  display:flex;
  flex-wrap: wrap;
  flex-direction:row;
  padding-inline-start: 1rem;

  &::before{
      content:'';
      background-color: var(--hero-overlay-color);
      opacity: var(--hero-overlay-opacity);
      background-blend-mode: multiply;
      inset:0 auto 0 -1rem;
      position: absolute;
      z-index: -1;
      width: 2rem;
  }
  span{
      flex: 0 1 auto;
      color: inherit;
      padding-block: 0.25em;
      padding-inline: 0 0.5em;
      position: relative;
      isolation: isolate;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  }
}

[data-item="hero-image-breadcrumb-nav"]::after{
  border-end-end-radius: $border_radius;
}
[data-item="cta-callout"]::after{
  border-radius:  $border_radius;
}


/*
Responsive Hero Image

The Responsive Hero Image component is used to display a responsive hero image that adapts to different screen sizes. the sizes are 1200/300, 1024/256,600/200, & 320/200


Markup:
<picture data-item="responsive-hero-image" class="{{modifier_class}} col_all grid row_all">
      <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/1200x300.png" media="(min-width: 1024px)" class="display_none">
      <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/1024x256.png" media="(min-width: 768px)" class="display_none">
      <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/600x300.png" media="(min-width: 400px)" class="display_none">
      <img src="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/320x450.png" alt="Hero Image" class="absolute inset_0 w_100">
</picture>

MarkupWrapperClasses: columns_1 grid relative rows_1 w_100

Weight: 0

NOTE: It utilizes the "Lorem Picsum" service for placeholder images. Replace all 4 images for best results.

caution:  This item is not finalized and might be subject to change

Styleguide Recipes.MicroSite.Sub.HeroImage.BackgroundImage


*/

/*
Responsive Hero Image Micro

The Responsive Hero Image component is used to display a responsive hero image that adapts to different screen sizes. the sizes are 1200/300, 1024/256,600/200, & 320/200


Markup:
    <div class="col_all overflow_hidden row_all">
        <picture data-item="responsive-hero-image" class="absolute bg_cover flex flex_column inset_0">
            <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/1200x300.png" media="(min-width: 1024px)" class="display_none">
            <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/1024x256.png" media="(min-width: 768px)" class="display_none">
            <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/600x300.png" media="(min-width: 400px)" class="display_none">
            <img src="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/320x450.png" alt="Hero Image" class="flex_100">
        </picture>
    </div>

MarkupWrapperClasses: columns_1 grid relative rows_1 w_100

Weight: 0

NOTE: It utilizes the "Lorem Picsum" service for placeholder images. Replace all 4 images for best results.

caution:  This item is not finalized and might be subject to change

Styleguide Recipes.MicroSite.Sub.HeroImage.BackgroundImageMicro


*/


/*
Page Break with Headline

This component represents a page break with a headline. It can be used to visually separate sections of a page or document. These Page Breaks are semanticaly an h2 with decorations. This shouldn't be used with other levels of Headlines. 

Markup Example:

<h2 class="c_primary-n1 flex flex_column flex_row:md font_display font_medium m_0 p-y_5:md p-y_4">
  <span class="flex_auto grid">
    <span class="br-t_1 br-tl_radius br_inherit br_solid self_center"></span>
  </span>
  <span class="flex_auto flex_shrink p-x_4 p-x_5:lg lh_1 text_center">
    Users are the Center of it All
  </span>
  <span class="flex_auto grid">
    <span class="br_primary-1 br-tl_radius br-t_1 br_solid br_inherit self_center"></span>
  </span>
</h2>


Note: These are styled heavier then a normal h2 and there are for eaiser understanding that this is a sperator of major content areas.

MarkupWrapperClasses: p_5 c_whtie

Weight: 100


Styleguide: Recipes.MicroSite.PageBreakHeadline
*/

/*
Microsite Structure Sub Design Patterns

<div class="reading-typography"><p>These sub design patterns are fundamental components that collectively form the structure of microsites. They provide the building blocks for organizing and presenting content in a cohesive and user-friendly manner. By utilizing these patterns, microsites can achieve a consistent and effective layout, enhancing the overall user experience.</p>
<p>These sub design patterns encompass various elements such as headers, footers, navigation menus, content sections, sidebars, and more. Each pattern serves a specific purpose and can be combined and customized to meet the unique requirements of a microsite. Together, they contribute to the seamless flow of information, enabling users to easily navigate and interact with the microsite.</p>
<p>By employing these sub design patterns, designers and developers can efficiently create microsites that are visually appealing, structurally sound, and optimized for user engagement. These patterns help maintain consistency across different sections of the microsite while allowing flexibility for customization and content integration.</p>
<p>In summary, these sub design patterns form the foundational structure of microsites, empowering designers and developers to craft engaging and functional digital experiences for their target audience.</p></div>


Weight: 99999

Styleguide: Recipes.MicroSite.Sub

*/


/*
Hero Column Split

The Hero Column Split component represents a layout where content is split into two columns: a main content column and a sidebar column. This layout is commonly used in hero sections of webpages to showcase important information or features.

Markup:
<div class="grid:md fles flex_column columns_5:lg columns_4:md gap-y_4 m-t_4 m-x_n4:md">
	<main main-content class="col-start_start col-end_n3 reading-typography p-x_4 p-x_5:lg">	
        <div class="bg_primary br_round p_5 c_white">main-content</div>
	</main>
	<aside sidebar class="col-start_n3:md col-end_end flex flex_column gap_4 p-x_4:md p-x_5:lg">
		<div class="bg_primary br_round p_5 c_white">sidebar</div>
	</aside>
</div>

MarkupWrapperClasses:
p_4

Weight: 0


Styleguide: Recipes.MicroSite.Layout.Sidebar
*/

